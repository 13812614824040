import React from "react";
import tw from "twin.macro";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "components/misc/Headings.js";
import StarIcon from "assets/icons/ArrowIcon";
import { Form, Input, message } from "antd";
import ContactManager from "services/api/ContactManager";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";

export default () => {
  const { t } = useTranslation();
  const TRANSLATION_PATH = "Components:Hero:RestaurantIntro";

  const [form] = Form.useForm();
  const country = Form.useWatch("country", form) ?? null;
  const phoneNumber = Form.useWatch("phone_number", form) ?? "";

  const heading = t(TRANSLATION_PATH + ".title");
  const headingHighlighted = t(TRANSLATION_PATH + ".title_highlighted");
  const description = t(TRANSLATION_PATH + ".description");
  const noContracts = t(TRANSLATION_PATH + ".no_contracts");
  const rating = t(TRANSLATION_PATH + ".rating");

  async function submit() {
    await form.validateFields();
    const values = await form.getFieldsValue();

    const { success, validationErrors } = await ContactManager.post(
      values
    );

    if (success) {
      message.success({
        content: t(TRANSLATION_PATH + ".toast.success"),
        duration: 0,
      });
      form.resetFields();
      return;
    }

    if (validationErrors) {
      Object.keys(validationErrors).forEach((field) => {
        validationErrors[field].forEach((error) => {
          message.error({
            content: error,
            duration: 3,
          });
        });
      });
      return;
    }

    return message.error({
      content: t(TRANSLATION_PATH + ".toast.error"),
      duration: 3,
    });
  }

  return (
    <Container>
      <Column1>
        <span>
          <HighlightedText>{headingHighlighted}</HighlightedText>
          <Heading>{heading}</Heading>
        </span>

        <Paragraph>{description}</Paragraph>
        <SubParagraph>{noContracts}</SubParagraph>
        <RatingContainer>
          <StarsContainer>
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
            <StarIcon />
          </StarsContainer>

          <RatingParagraph>{rating}</RatingParagraph>
        </RatingContainer>
      </Column1>
      <Column2>
        <FormContainer>
          <FormTitle>{t(TRANSLATION_PATH + ".form.title")}</FormTitle>
          <Form form={form}>
            <Form.Item name="name" rules={[{ required: true }]}>
              <Input
                placeholder={t(TRANSLATION_PATH + ".form.fields.name")}
                size="large"
              />
            </Form.Item>
            <Form.Item name="restaurant_name" rules={[{ required: true }]}>
              <Input
                placeholder={t(
                  TRANSLATION_PATH + ".form.fields.restaurant_name"
                )}
                size="large"
              />
            </Form.Item>
            <Form.Item name="restaurant_address" rules={[{ required: true }]}>
              <Input
                placeholder={t(
                  TRANSLATION_PATH + ".form.fields.restaurant_address"
                )}
                size="large"
              />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true }]}>
              <Input
                placeholder={t(TRANSLATION_PATH + ".form.fields.email")}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="country"
              rules={[{ required: true }]}
              style={{
                marginBottom: 0,
                display: "none",
              }}
              initialValue={"DE"}
            />
            <Form.Item
              name="phone_number"
              rules={[{ required: true }]}
              initialValue={""}
            >
              <PhoneNumberInput
                id="phone_number"
                value={phoneNumber}
                country={country}
                placeholder="12345678"
                onChange={(value) => form.setFieldValue("phone_number", value)}
                onChangeCountry={(value) =>
                  form.setFieldValue("country", value)
                }
              />
            </Form.Item>
            <PrimaryAction onClick={submit}>
              {t(TRANSLATION_PATH + ".form.button.demo")}
            </PrimaryAction>
          </Form>
        </FormContainer>
      </Column2>
    </Container>
  );
};

const Container = tw.div`relative lg:pb-24 lg:pt-12 pb-12 pt-0 lg:px-24 px-6 grid lg:grid-cols-5 grid-cols-1 gap-[24px] justify-center items-center`;
const Heading = tw(
  SectionHeading
)`font-black text-black lg:text-left text-center text-3xl sm:text-5xl lg:text-6xl leading-tight mb-0`;

const HighlightedText = tw(
  Heading
)`text-primary-default lg:text-left text-center`;

const Paragraph = tw.p`mb-8 text-black font-medium mt-4 lg:text-left text-center`;
const SubParagraph = tw.p`mb-8 text-[14px] text-gray-default font-medium mt-4 lg:text-left text-center`;
const StarsContainer = tw.div`flex gap-2`;
const RatingContainer = tw.div`flex lg:flex-row flex-col mt-4 gap-2 lg:items-start items-center`;
const RatingParagraph = tw.p`text-gray-default lg:text-left text-center text-[14px]`;

const FormContainer = tw.div`flex flex-col p-6 shadow-xl bg-white rounded-2xl gap-3`;
const FormTitle = tw.h6`text-black`;

const Column1 = tw.div`lg:col-span-3 col-span-1`;
const Column2 = tw.div`lg:col-span-2 col-span-1`;

const PrimaryAction = tw.button`no-underline rounded px-4 py-3 text-lg font-medium shadow transition duration-300 bg-primary-default text-white hocus:shadow-lg hocus:text-white text-center`;
