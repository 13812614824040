export default {
  title: "für Restaurants leicht gemacht",
  title_highlighted: "Online-Wachstum",
  description:
    "Tausende von Restaurantbesitzern nutzen Foodamigos, um online erfolgreich zu sein. Steigere deinen Umsatz mit einem großartigen Online-Erlebnis – genau wie die großen Franchises.",
  no_contracts:
    "Keine Verträge. Kein langfristiges Engagement. Nichts zu verlieren. ",
  rating: "Top bewertet bei tausenden von Restaurants",
  form: {
    title: "Gratis Demo - Wir rufen dich innerhalb von 24 Stunden zurück",
    fields: {
      name: "Dein Name",
      restaurant_name: "Restaurant name",
      restaurant_address: "Restaurant Adresse",
      email: "Email Adresse",
      phone_number: "Handynummer",
    },
    button: {
      demo: "Gratis Demo Erhalten",
    },
  },
  toast: {
    success:
      "Wir haben deine Anfrage erhalten und werden dich in Kürze kontaktieren",
    error:
      "Demo-Anfrage konnte nicht gesendet werden. Bitte versuchen Sie es erneut.",
  },
};
